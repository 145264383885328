import React, { useState, useRef, useMemo } from 'react';
import '../styles/TrWritingSelectionComponent.scss';
import TrAlarmComponent from './TrAlarmComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const TrWritingSelectionComponent = ({
    onNext,
    onPrevious,
    selection_list,
    selected_magickid,
}) => {
    const [selectedButton, setSelectedButton] = useState({});
    const selection_titles = selection_list.selection;
    const selection_images = selection_list.image;
    const alertTitle = selection_list.category;
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isStepBtnsActive, setIsStepBtnsActive] = useState(false);
    const swiperRef = useRef(null);

    // 선택지 랜덤으로 셋팅
    const [shuffledTitles, shuffledImages] = useMemo(() => {
        if (selection_titles.length !== selection_images.length) {
            console.error("selection_titles과 selection_images의 길이가 맞지 않습니다.");
            return [selection_titles, selection_images];
        }

        const combined = selection_titles.map((title, index) => ({
            title,
            image: selection_images[index],
        }));

        // 배열을 랜덤하게 섞기
        for (let i = combined.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [combined[i], combined[j]] = [combined[j], combined[i]];
        }

        return [
            combined.map(item => item.title),
            combined.map(item => item.image),
        ];
    }, [selection_titles, selection_images]);



    const handleButtonClick = (index) => {
        setSelectedButton(shuffledTitles[index]);
        setSelectedItem(index);
        setShowAlert(false);
        setIsStepBtnsActive(true);
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext(); // 슬라이더만 이동
        }
    };

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev(); // 슬라이더만 이동
        }
    };

    const handleNextButtonClick = () => {
        if (Object.keys(selectedButton).length === 0) {
            setShowAlert(true);
        } else {
            onNext(selectedButton); // 다음 동작 수행
        }
    };

    const addClassToWrapper = (swiper, titles) => {
        if (swiper && swiper.wrapperEl) {
            const className = titles.length <= 2 ? 'few-items' : null;
            if (className) {
                swiper.wrapperEl.classList.add(className);
            }
        }
    };

    return (
        <>
            <div className="tr-bg w-selection">
                <div className="inner">
                    <div className="tr-box w-selection">
                        <div className="text-wrap">
                            <p className="title-text">
                                <span>
                                    {selection_list.category}을/를 골라볼까요?
                                </span>
                            </p>
                            <span>총 {selection_titles.length}개</span>
                        </div>
                        <div className="arrow-btns">
                            <button
                                className="arrow arrow-prev"
                                onClick={handlePrevClick}
                            >
                                <img
                                    src={'/images/prev-arrow-red.svg'}
                                    alt="이전"
                                />
                            </button>
                            <button
                                className="arrow arrow-next"
                                onClick={handleNextClick}
                            >
                                <img
                                    src={'/images/next-arrow-red.svg'}
                                    alt="다음"
                                />
                            </button>
                        </div>
                        <Swiper
                            ref={swiperRef}
                            modules={[Pagination]}
                            slidesPerView={3}
                            spaceBetween={20}
                            pagination={{ clickable: true }}
                            // centeredSlides={true}
                            loop={true}
                            className="writing-selection-container"
                            onSwiper={(swiper) =>
                                addClassToWrapper(swiper, shuffledTitles)
                            }
                        >
                            {shuffledTitles.map((title, index) => {
                                const realIndex =
                                    (currentIndex + index) %
                                    shuffledTitles.length;

                                return (
                                    <SwiperSlide
                                        key={index}
                                        className={`writing-selections ${selectedItem === index
                                            ? 'border'
                                            : ''
                                            }`}
                                        onClick={() => handleButtonClick(index)}
                                    >
                                        {selectedItem === index &&
                                            shuffledImages.length > 1 && (
                                                <figure className="writing-image-container">
                                                    <img
                                                        src={
                                                            shuffledImages[
                                                            realIndex
                                                            ]
                                                        }
                                                        alt={title}
                                                    />
                                                </figure>
                                            )}
                                        <p>{title}</p>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                    <div
                        className={`step-btns ${isStepBtnsActive ? 'active' : ''
                            }`}
                    >
                        <div
                            className="button button-prev"
                            onClick={onPrevious}
                        >
                            ◀ 이전
                        </div>
                        <div
                            className="button button-next"
                            onClick={handleNextButtonClick}
                        >
                            다음 ▶
                        </div>
                    </div>
                </div>
            </div>
            {showAlert && (
                <TrAlarmComponent
                    onClose={() => setShowAlert(false)}
                    title={alertTitle}
                    selected_magickid={selected_magickid}
                />
            )}
        </>
    );
};

export default TrWritingSelectionComponent;
